import {
  IAppService,
  IAppServiceWithSharedComponent,
  IAuthExceptionServiceEndPoints,
  IPartnerBundle,
  IServiceEndPoints,
  ISharedComponentConfig,
  ISharedComponents,
} from '@fxp/fxpservices'
import { environment } from './app/common/services/config/environmentHelper'
import { PartnerAppRegistrationService } from '@fxp/fxpservices';

class GrmWebApplication_routes implements IAppService, IAppServiceWithSharedComponent {
  getRoutes(userContext: any): any {
    const routeInfo: any = {
      applicationName: 'grmWebApplication',
      adminRules: {
        ResourcePermissions: {
          AllResourcesMandatory: true,
          Value: [
            {
              Resource: 'FxPPlatform.ResourceManagement',
              Permissions: { Value: ['All'] },
            },
          ],
        },
      },
      sharedBundles: [],
      routes: [
        {
          name: 'opt-in-out-notifications',
          url: '/optinoutnotifications',

generatedBundle: 'c0418260459b-RequestXpModule-Bundle',
          data: {
            headerName: 'Opt In Out Notification',
            breadcrumbText: 'Opt In Out Notification',
            pageTitle: 'Opt In Out Notification',
            partnerTelemetryName: 'GRM',
          },
          authorizationRules: {
            AllowedPermissions: {
              ResourcePermissions: {
                Value: [
                  {
                    Resource: 'PS.QueueSubscription',
                    Permissions: { Value: ['Add', 'Edit', 'View', 'Approve'] },
                  },
                ],
              },
            },
          },
        },
        {
          name: 'grm-manage-queues',
          url: '/manage-queue-subscriptions/:tab?',

generatedBundle: 'c0418260459b-ManageQueuesModule-Bundle',
          data: {
            headerName: 'Manage Queue Subscriptions',
            breadcrumbText: 'Manage Queue Subscriptions',
            pageTitle: 'Manage Queue Subscriptions',
            partnerTelemetryName: 'GRM',
          },
          authorizationRules: {
            AllowedPermissions: {
              ResourcePermissions: {
                Value: [
                  {
                    Resource: 'PS.QueueSubscription',
                    Permissions: { Value: ['Add', 'Edit', 'View', 'Approve'] },
                  },
                ],
              },
            },
          },
        },
        {
          name: 'grm-resource-manager-experience',
          url: '/rm-dashboard',

generatedBundle: 'c0418260459b-RmXpModule-Bundle',
          data: {
            headerName: 'RM Dashboard',
            breadcrumbText: 'RM Dashboard',
            pageTitle: 'RM Dashboard',
            partnerTelemetryName: 'GRM',
          },
          authorizationRules: {
            AllowedPermissions: {
              ResourcePermissions: {
                Value: [
                  {
                    Resource: 'PS.Project',
                    Permissions: { Value: ['Read_All'] },
                  },
                ],
              },
            },
          },
        },
        {
          name: 'grm-role-details-experience',
          url: '/project/:projectId/resource/:resourceId/:isDraft/:persona',

generatedBundle: 'c0418260459b-RequestXpModule-Bundle',
          data: {
            headerName: 'Role Details',
            breadcrumbText: 'Role Details',
            pageTitle: 'Role Details',
            partnerTelemetryName: 'GRM',
          },
          authorizationRules: {
            AllowedPermissions: {
              ResourcePermissions: {
                Value: [
                  {
                    Resource: 'PS.Project',
                    Permissions: { Value: ['Add', 'Edit', 'View'] },
                  },
                ],
              },
            },
          },
        },
        {
          name: 'grm-role-details-experience-with-source',
          url:
            '/project/:projectId/resource/:resourceId/:isDraft/:persona/source/:source',

generatedBundle: 'c0418260459b-RequestXpModule-Bundle',
          data: {
            headerName: 'Role Details',
            breadcrumbText: 'Role Details',
            pageTitle: 'Role Details',
            partnerTelemetryName: 'GRM',
          },
          authorizationRules: {
            AllowedPermissions: {
              ResourcePermissions: {
                Value: [
                  {
                    Resource: 'PS.Project',
                    Permissions: { Value: ['Add', 'Edit', 'View'] },
                  },
                ],
              },
            },
          },
        },
        {
          name: 'cce-queue-subscription',
          url: '/queue-subscription/:businessType',

generatedBundle: 'c0418260459b-RequestXpModule-Bundle',
          data: {
            headerName: 'Queue Subscription',
            breadcrumbText: 'Queue Subscription',
            pageTitle: 'Queue Subscription',
            partnerTelemetryName: 'GRM',
          },
          authorizationRules: {
            AllowedPermissions: {
              ResourcePermissions: {
                Value: [
                  {
                    Resource: 'FxPPlatform.CSA_Admin',
                    Permissions: { Value: ['All'] },
                  },
                ],
              },
            },
          },
        },

        {
          name: 'TestMCS',
          url: '/TestMCS',

generatedBundle: 'c0418260459b-McsRequestXp-Bundle',
          data: {
            headerName: 'TestMCS',
            breadcrumbText: 'TestMCS',
            pageTitle: 'TestMCS',
            partnerTelemetryName: 'GRM',
          },
        },

        {
          name: 'grm-queue-authoring',
          url: '/grm-queue-authoring/:businessType',

generatedBundle: 'c0418260459b-QueueAdminModule-Bundle',
          data: {
            headerName: 'Queue Management',
            breadcrumbText: 'Queue Management',
            pageTitle: 'Queue Management',
            partnerTelemetryName: 'GRM',
          },
          authorizationRules: {
            AllowedPermissions: {
              ResourcePermissions: {
                Value: [
                  {
                    Resource: 'FxPPlatform.ResourceManagement',
                    Permissions: { Value: ['All'] },
                  },
                  {
                    Resource: 'FxPPlatform.CSA_Admin',
                    Permissions: { Value: ['All'] },
                  },
                ],
              },
            },
          },
        },
        {
          name: 'grm-businessrule-authoring',
          url: '/grm-businessrule-authoring',

generatedBundle: 'c0418260459b-BusinessRuleAdminModule-Bundle',
          data: {
            headerName: 'Business Rule Management',
            breadcrumbText: 'Business Rule Management',
            pageTitle: 'Business Rule Management',
            partnerTelemetryName: 'GRM',
          },
          authorizationRules: {
            AllowedPermissions: {
              ResourcePermissions: {
                Value: [
                  {
                    Resource: 'FxPPlatform.CSA_Admin',
                    Permissions: { Value: ['All'] },
                  },
                ],
              },
            },
          },
        },
        {
          name: 'cce-businessrule-subscription',
          url: '/businessrule-subscription',

generatedBundle: 'c0418260459b-BusinessRuleAdminModule-Bundle',
          data: {
            headerName: 'BusinessRule Subscription',
            breadcrumbText: 'BusinessRule Subscription',
            pageTitle: 'BusinessRule Subscription',
            partnerTelemetryName: 'GRM',
          },
          authorizationRules: {
            AllowedPermissions: {
              ResourcePermissions: {
                Value: [
                  {
                    Resource: 'FxPPlatform.CSA_Admin',
                    Permissions: { Value: ['All'] },
                  },
                ],
              },
            },
          },
        },
        {
          name: 'manage-teams-channels',
          url: '/manage-teams-channels',

generatedBundle: 'c0418260459b-RequestXpModule-Bundle',
          data: {
            headerName: 'Manage Teams Channels',
            breadcrumbText: 'Manage Teams Channels',
            pageTitle: 'Manage Teams Channels',
            partnerTelemetryName: 'GRM',
          },
          authorizationRules: {
            AllowedPermissions: {
              ResourcePermissions: {
                Value: [
                  {
                    Resource: 'FxPPlatform.CSA_Admin',
                    Permissions: { Value: ['All'] },
                  },
                ],
              },
            },
          },
        },
        {
          name: 'mcs-activity-feed',
          url: '/mcs-activity-feed',

generatedBundle: 'c0418260459b-MCSRequestTabsSharedModule-Bundle',
          data: {
            headerName: 'Business Rule Management',
            breadcrumbText: 'Business Rule Management',
            pageTitle: 'Business Rule Management',
            partnerTelemetryName: 'GRM',
          },
          authorizationRules: {
            AllowedPermissions: {
              ResourcePermissions: {
                Value: [
                  {
                    Resource: 'FxPPlatform.CSA_Admin',
                    Permissions: { Value: ['All'] },
                  },
                ],
              },
            },
          },
        },
        {
          name: 'manage-request-tags',
          url: '/manage-request-tags',

generatedBundle: 'c0418260459b-RequestXpModule-Bundle',
          data: {
            headerName: 'Manage Request Tags',
            breadcrumbText: 'Manage Request Tags',
            pageTitle: 'Manage Request Tags',
            partnerTelemetryName: 'GRM',
          },
          authorizationRules: {
            AllowedPermissions: {
              Roles: {
                Value: [
                  {
                    RoleName: 'PS.CSAManager',
                  },
                ],
              },
            },
          },
        },
      ],
    }

    return routeInfo
  }

  getServiceEndPoints(): Array<IServiceEndPoints> {
    const endpoints: IServiceEndPoints[] = environment().ServiceEndPoints

    return endpoints
  }

  getAuthExceptionEndpoints(): IAuthExceptionServiceEndPoints[] {
    const authExceptionEndpoints: IAuthExceptionServiceEndPoints[] = environment()
      .AuthExceptionEndpoints

    return authExceptionEndpoints
  }

  public getBundles(): Array<IPartnerBundle> {
    const baseUrl = environment().cdnBaseUrl
    const bundles = [
      {
        name: 'grm-app-bundle',
        files: [
          baseUrl + '/vendor.bundle.js',
          baseUrl + '/styles.bundle.js',
          baseUrl + '/grm-common.bundle.js',
          baseUrl + '/taco-components.bundle.js',
          baseUrl + '/grm-actions.bundle.js',
          baseUrl + '/grm-queue-mgmt.bundle.js',
          baseUrl + '/grm-rm-xp.bundle.js',
          baseUrl + '/grm-request-xp.bundle.js',
          baseUrl + '/verge-xp.bundle.js',
          baseUrl + '/grm2.bundle.js',
        ],
        sequentialLoading: true,
      },
    ]

    return bundles
  }

  getSharedComponentsInfo(): ISharedComponents {
    const feedbackSharedComponent: ISharedComponentConfig = {

generatedBundle: 'c0418260459b-RequestXpModule-Bundle',
      componentDescription: 'This component renders feedback component.',
      componentInputs: [], // optional: Provide the list of input parameters with description.
      componentOutputs: [], // optional: Provide the list of output parameters with description.
      componentName: 'feedback-component',
    }

    const leadTimeComponent: ISharedComponentConfig = {

generatedBundle: 'c0418260459b-HawkEyeModule-Bundle',
      componentDescription: 'This component renders mini card.',
      componentInputs: [], // optional: Provide the list of input parameters with description.
      componentOutputs: [], // optional: Provide the list of output parameters with description.
      componentName: 'mini-card',
    }

    const CCEAdvertiseComponent: ISharedComponentConfig = {

generatedBundle: 'c0418260459b-RequestXpModule-Bundle',
      componentDescription: 'This component renders Advertise component.',
      componentInputs: [], // optional: Provide the list of input parameters with description.
      componentOutputs: [], // optional: Provide the list of output parameters with description.
      componentName: 'cce-advertise-milestone',
    }
    const CCEBulkActionComponent: ISharedComponentConfig = {

generatedBundle: 'c0418260459b-RequestXpModule-Bundle',
      componentDescription: 'This component renders CCE Bulk Action component.',
      componentInputs: [], // optional: Provide the list of input parameters with description.
      componentOutputs: [], // optional: Provide the list of output parameters with description.
      componentName: 'cce-bulk-actions',
    }

    const advertiseLogSharedComponent: ISharedComponentConfig = {

generatedBundle: 'c0418260459b-RequestXpModule-Bundle',
      componentDescription: 'This component renders advertise log component.',
      componentInputs: [], // optional: Provide the list of input parameters with description.
      componentOutputs: [], // optional: Provide the list of output parameters with description.
      componentName: 'cce-advertise-log',
    }
    const vergeNotificationMessageComponent: ISharedComponentConfig = {

generatedBundle: 'c0418260459b-RequestXpModule-Bundle',
      componentDescription:
        'This component renders notification message component.',
      componentInputs: [
        ['notificationMessage', 'Provide notification message and category'],
      ],
      componentOutputs: [], // optional: Provide the list of output parameters with description.
      componentName: 'verge-notification-message',
    }
    const clarityInsights: ISharedComponentConfig = {

generatedBundle: 'c0418260459b-CommonWebModule-Bundle',
      componentDescription:
        'This component enables activity tracking for Clarity',
      componentInputs: [
        ['projectCode', 'mandatory project code from Clarity registration'],
        [
          'customTags',
          'optional Map<string,string> (tag-name, value) of custom tags to be associated with the insights',
        ],
      ],
      componentOutputs: [], // optional: Provide the list of output parameters with description.
      componentName: 'clarity',
    }

    const sampleWorkbenchComponent: ISharedComponentConfig = {

generatedBundle: 'c0418260459b-VergeSharedModule-Bundle',
      componentDescription:
        'Sample shared component to be utilized in Wrokbench',
      componentInputs: [],
      componentOutputs: [],
      componentName: 'workbench-sample',
    }

    const sampleComingSoonComponent: ISharedComponentConfig = {

generatedBundle: 'c0418260459b-VergeSharedModule-Bundle',
      componentDescription:
        'Sample shared component to be utilized in Workbench',
      componentInputs: [],
      componentOutputs: [],
      componentName: 'coming-soon',
    }

    const engagementTabComponent: ISharedComponentConfig = {

generatedBundle: 'c0418260459b-VergeSharedModule-Bundle',
      componentDescription:
        'Shared component to be utilized in Workbench for Engagement',
      componentInputs: [],
      componentOutputs: [],
      componentName: 'workbench-engagement',
    }

    const projectTabComponent: ISharedComponentConfig = {

generatedBundle: 'c0418260459b-VergeSharedModule-Bundle',
      componentDescription:
        'Shared component to be utilized in Workbench for Engagement',
      componentInputs: [],
      componentOutputs: [],
      componentName: 'workbench-project',
    }

    const dealWorkbenchComponent: ISharedComponentConfig = {

generatedBundle: 'c0418260459b-VergeSharedModule-Bundle',
      componentDescription:
        'Shared component to be utilized in Workbench for Deal',
      componentInputs: [],
      componentOutputs: [],
      componentName: 'deal-workbench',
    }

    const packageWorkbenchComponent: ISharedComponentConfig = {

generatedBundle: 'c0418260459b-VergeSharedModule-Bundle',
      componentDescription:
        'Shared component to be utilized in Workbench for Package',
      componentInputs: [],
      componentOutputs: [],
      componentName: 'package-workbench',
    }

    const vergeDashboardComponent: ISharedComponentConfig = {

generatedBundle: 'c0418260459b-HawkEyeModule-Bundle',
      componentDescription: 'Shared component for operational dashboard',
      componentInputs: [],
      componentOutputs: [],
      componentName: 'v-dashboard',
    }

    const MCSRequestActionComponent: ISharedComponentConfig = {

generatedBundle: 'c0418260459b-McsRequestXpSharedModule-Bundle',
      componentDescription: 'Actions for MCS Request',
      componentInputs: [
        ['userPersona', 'ResourceManager | Requestor'],
        ['isDisabledFromParent', 'used to enable or disable action'],
        ['loggedInUserAlias', 'OBO/loggedin user alias'],
        ['actionName', 'Action Name'],
        ['resourceRequestModel', 'Array of search resource requests'],
      ],
      componentOutputs: [],
      componentName: 'mcs-request-action',
    }

    const ReconMCSComponent: ISharedComponentConfig = {

generatedBundle: 'c0418260459b-McsRequestXpSharedModule-Bundle',
      componentDescription: 'Recon for MCS Request',
      componentInputs: [
        ['userPersona', 'ResourceManager | Requestor'],
        ['pageEditable', 'Boolean Field for editable conmponent'],
        ['loggedInUserAlias', 'OBO/loggedin user alias'],
      ],
      componentOutputs: [],
      componentName: 'recon-mcs',
    }

    const McsActivityFeedComponent: ISharedComponentConfig = {

generatedBundle: 'c0418260459b-MCSRequestTabsSharedModule-Bundle',
      componentDescription: 'Activity Feed for MCS Request',
      componentInputs: [],
      componentOutputs: [],
      exposeAsExternalComponents: true,
      componentName: 'mcs-activity-feed',
    }
    const rmNotesComponent: ISharedComponentConfig = {

generatedBundle: 'c0418260459b-RequestXpModule-Bundle',
      componentDescription:
        'This component renders resource manager notes component',
      componentInputs: [],
      componentOutputs: [],
      componentName: 'rm-notes',
    }

    const WorkbenchSingleRequestViewComponent: ISharedComponentConfig = {

generatedBundle: 'c0418260459b-WorkbenchSingleReqViewSharedModule-Bundle',
      componentDescription: 'Single Request View',
      componentInputs: [
        ['pageEditable', 'Page Editable'],
        ['editMode', 'Page read only/ Edit mode'],
        ['resourceRequestId', 'Resource Request Id'],
        ['loggedInUserAlias', 'OBO/loggedin user alias'],
        ['userPersona', 'ResourceManager | Requestor'],
      ],
      componentOutputs: [],
      componentName: 'single-request-view',
    }

    const WorkbenchMcsRequestScheduleComponent: ISharedComponentConfig = {

generatedBundle: 'c0418260459b-WorkbenchSingleReqViewSharedModule-Bundle',
      componentDescription: 'MCS Request Schedule',
      componentInputs: [
        ['searchResourceRequestResponse', 'ResourceRequest Object'],
        ['editMode', 'Page read only/ Edit mode'],
        ['pageEditable', 'Editable / Not'],
        ['userPersona', 'ResourceManager | Requestor'],
        ['_resourceRequestId', 'Resource Request Id'],
        ['loggedInUserAlias', 'user alias'],
        ['requestedDurationUnit', 'Duration Unit'],
        ['requestedLoadType', 'Load Type'],
        ['requestedStartDate', 'Start Date'],
        ['requestedEndDate', 'End Date'],
        ['schedulePatternUnit', 'Schedule pattern unit'],
        ['requestedDuration', 'Request Duration'],
        ['schedulePatterns', 'Schedule Pattern'],
        ['isRequestDataCleared', 'Data Cleared'],
        ['isDeliveryProject', 'Delivery project'],
      ],
      componentOutputs: [],
      componentName: 'requested-schedule-mcs',
    }

    const WorkbenchTalentSwapComponent: ISharedComponentConfig = {

generatedBundle: 'c0418260459b-WorkbenchSingleReqViewSharedModule-Bundle',
      componentDescription: 'Talent Swap',
      componentInputs: [
        ['projectRequest', ' Project Request'],
        ['actionName', 'Action Name'],
        ['userPersona', 'ResourceManager | Requestor'],
        ['loggedInUserAlias', 'user alias'],
        ['isDisabledFromParent', 'used to enable or disable action'],
        ['context', 'WorkbenchContext'],
        ['inputConfig', 'Input Config'],
      ],
      componentName: 'shared-talent-swap',
    }

    const WorkbenchSOWLinkDetailsComponent: ISharedComponentConfig = {

generatedBundle: 'c0418260459b-SOWLinkSharedModule-Bundle',
      componentDescription: 'SOW Link Details',
      componentInputs: [],
      componentOutputs: [],
      componentName: 'sow-link',
    }
    const WorkbenchDemandDetailsComponent: ISharedComponentConfig = {

generatedBundle: 'c0418260459b-WorkbenchWrapperSharedModule-Bundle',
      componentDescription: 'Demand Details',
      componentInputs: [],
      componentOutputs: [],
      componentName: 'demand-details-mcs',
    }

    const WorkbenchRequestEditComponent: ISharedComponentConfig = {

generatedBundle: 'c0418260459b-WorkbenchRequestEditComponentdModule-Bundle',
      componentDescription: 'Request Edit',
      componentInputs: [],
      componentOutputs: [],
      componentName: 'request-edit-mcs',
    }

    const WorkbenchRequestorGridComponent: ISharedComponentConfig = {

generatedBundle: 'c0418260459b-RequestorGridModule-Bundle',
      componentDescription: 'Requestor Grid',
      componentInputs: [],
      componentOutputs: [],
      componentName: 'requestor-grid',
    }

    const networkConnectionsComponent: ISharedComponentConfig = {

generatedBundle: 'c0418260459b-VergeModule-Bundle',
      componentDescription: 'Network Connections Component',
      componentInputs: [],
      componentOutputs: [],
      componentName: 'network-connections',
    }

    const networkWbSharedComponent: ISharedComponentConfig = {

generatedBundle: 'c0418260459b-VergeModule-Bundle',
      componentDescription: 'Network Connections Component',
      componentInputs: [],
      componentOutputs: [],
      componentName: 'network-wbshared',
    }

    const ondemandNotificationsComponent: ISharedComponentConfig = {

generatedBundle: 'c0418260459b-RequestXpModule-Bundle',
      componentDescription:
        'This component renders on demand notifications component',
      componentInputs: [],
      componentOutputs: [],
      componentName: 'on-demand-notifications',
    }

    const notificationEmailComponent: ISharedComponentConfig = {

generatedBundle: 'c0418260459b-RequestXpModule-Bundle',
      componentDescription:
        'This component renders notification email component',
      componentInputs: [],
      componentOutputs: [],
      componentName: 'notification-email',
    }

    const vergeFeedbackSharedComponent: ISharedComponentConfig = {

generatedBundle: 'c0418260459b-VergeFeedbackModule-Bundle',
      componentDescription: 'This component renders verge feedback component.',
      componentInputs: [
        ['sourceComponentName', ''],
        ['sourceAppName', ''],
        ['surveyID', ''],
      ], // optional: Provide the list of input parameters with description.
      componentOutputs: [], // optional: Provide the list of output parameters with description.
      componentName: 'verge-feedback',
    }

    const requestedScheduleComponent: ISharedComponentConfig = {

generatedBundle: 'c0418260459b-RequestXpModule-Bundle',
      componentDescription: 'This component renders requested schedule',
      componentInputs: [],
      componentOutputs: [],
      componentName: 'requested-schedule',
    }

    const workbenchNotificationWrapperComponent: ISharedComponentConfig = {

generatedBundle: 'c0418260459b-WorkbenchNotificationEmailModule-Bundle',
      componentDescription:
        'This component renders workbench notification email',
      componentInputs: [
        ['templateConfig', 'templateConfig'],
        ['context', 'WorkbenchContext'],
        ['showModal', 'Show Modal boolean'],
        ['resourceRequestModel', 'Search Resource Requests'],
        ['additionalParams', 'additional Parameters'],
      ],
      componentOutputs: [['OnAction', 'sendFromInbox']],
      componentName: 'workbench-notification-wrapper',
    }

    // csa-nav
    const csaSearchNavigationComponent: ISharedComponentConfig = {

generatedBundle: 'c0418260459b-CSAModule-Bundle',
      componentDescription: 'CCE Component that displays My Requests',
      componentInputs: [],
      componentOutputs: [],
      componentName: 'app-csa-search-navigation',
    }

    const WorkbenchRequestorMainComponent: ISharedComponentConfig = {

generatedBundle: 'c0418260459b-MainContainerModule-Bundle',
      componentDescription: '',
      componentInputs: [
        ['resourceRequestId', 'resourceRequestId'],
        ['requestCategory', 'requestCategory'],
        ['selectedRequests', 'selectedRequests'],
        ['entityType', 'entityType'],
        ['disableFilter', 'disableFilter'],
        ['actionContext', 'actionContext'],
      ],
      componentOutputs: [],
      componentName: 'requestor-container',
    }

    const ManageCoauthorSharedComponent: ISharedComponentConfig = {

generatedBundle: 'c0418260459b-RequestXpModule-Bundle',
      componentDescription:
        'This component renders Coauthor details of the project.',
      componentInputs: [
        ['projTeam', 'All Coauthors'],
        ['projectid', 'Project id'],
        ['loggedInUser', 'Alias of logged in user'],
        ['ProjectClick', 'Captures click on project to update coauthors'],
      ],
      componentOutputs: [], // optional: Provide the list of output parameters with description.
      componentName: 'manageCoauthor',
    }

    const WorkbenchProjectDetailsComponent: ISharedComponentConfig = {

generatedBundle: 'c0418260459b-WorkbenchSingleReqViewSharedModule-Bundle',
      componentDescription:
        'This component renders ces project details component.',
      componentInputs: ['workbenchContext'], // optional: Provide the list of input parameters with description.
      componentOutputs: [], // optional: Provide the list of output parameters with description.
      componentName: 'ces-project-details',
    }

    return {
      appName: 'grmWebApplication',
      components: [
        feedbackSharedComponent,
        leadTimeComponent,
        CCEAdvertiseComponent,
        advertiseLogSharedComponent,
        clarityInsights,
        CCEBulkActionComponent,
        sampleWorkbenchComponent,
        sampleComingSoonComponent,
        dealWorkbenchComponent,
        packageWorkbenchComponent,
        MCSRequestActionComponent,
        ReconMCSComponent,
        engagementTabComponent,
        projectTabComponent,
        rmNotesComponent,
        networkConnectionsComponent,
        ondemandNotificationsComponent,
        notificationEmailComponent,
        requestedScheduleComponent,
        WorkbenchSingleRequestViewComponent,
        WorkbenchMcsRequestScheduleComponent,
        WorkbenchDemandDetailsComponent,
        WorkbenchRequestEditComponent,
        WorkbenchRequestorGridComponent,
        McsActivityFeedComponent,
        vergeFeedbackSharedComponent,
        vergeDashboardComponent,
        networkWbSharedComponent,
        workbenchNotificationWrapperComponent,
        WorkbenchDemandDetailsComponent,
        vergeNotificationMessageComponent,
        WorkbenchRequestorMainComponent,
        csaSearchNavigationComponent,
        WorkbenchSOWLinkDetailsComponent,
        ManageCoauthorSharedComponent,
        WorkbenchProjectDetailsComponent,
        WorkbenchTalentSwapComponent,
      ],
      disableSharing: false,
      sharedBundles: [],
    }
  }
  public getGeneratedBundles() {
    var baseUrl = ''
    var currentScriptUrl = document.currentScript['src']
    if (currentScriptUrl.indexOf('exclude.inline.bundle') > 0) {
      currentScriptUrl = new Error().stack.match(/https?:\/\/[^\s/]+(?:\/?)/g)
      baseUrl = currentScriptUrl
        .filter((item) => item.indexOf('localhost:5000') === -1)[0]
        .toLowerCase()
    } else {
      baseUrl = currentScriptUrl.substring(0, currentScriptUrl.lastIndexOf('/'))
    }
    return [
            {
                name: 'c0418260459b-RequestXpModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/RequestXpModule.vendor.bundle.js',
                    baseUrl + '/RequestXpModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: 'c0418260459b-ManageQueuesModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/ManageQueuesModule.vendor.bundle.js',
                    baseUrl + '/ManageQueuesModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: 'c0418260459b-RmXpModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/RmXpModule.vendor.bundle.js',
                    baseUrl + '/RmXpModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: 'c0418260459b-McsRequestXp-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/McsRequestXp.vendor.bundle.js',
                    baseUrl + '/McsRequestXp.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: 'c0418260459b-QueueAdminModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/QueueAdminModule.vendor.bundle.js',
                    baseUrl + '/QueueAdminModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: 'c0418260459b-BusinessRuleAdminModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/BusinessRuleAdminModule.vendor.bundle.js',
                    baseUrl + '/BusinessRuleAdminModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: 'c0418260459b-MCSRequestTabsSharedModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/MCSRequestTabsSharedModule.vendor.bundle.js',
                    baseUrl + '/MCSRequestTabsSharedModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: 'c0418260459b-HawkEyeModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/HawkEyeModule.vendor.bundle.js',
                    baseUrl + '/HawkEyeModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: 'c0418260459b-CommonWebModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/CommonWebModule.vendor.bundle.js',
                    baseUrl + '/CommonWebModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: 'c0418260459b-VergeSharedModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/VergeSharedModule.vendor.bundle.js',
                    baseUrl + '/VergeSharedModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: 'c0418260459b-McsRequestXpSharedModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/McsRequestXpSharedModule.vendor.bundle.js',
                    baseUrl + '/McsRequestXpSharedModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: 'c0418260459b-WorkbenchSingleReqViewSharedModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/WorkbenchSingleReqViewSharedModule.vendor.bundle.js',
                    baseUrl + '/WorkbenchSingleReqViewSharedModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: 'c0418260459b-SOWLinkSharedModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/SOWLinkSharedModule.vendor.bundle.js',
                    baseUrl + '/SOWLinkSharedModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: 'c0418260459b-WorkbenchWrapperSharedModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/WorkbenchWrapperSharedModule.vendor.bundle.js',
                    baseUrl + '/WorkbenchWrapperSharedModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: 'c0418260459b-WorkbenchRequestEditComponentdModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/WorkbenchRequestEditComponentdModule.vendor.bundle.js',
                    baseUrl + '/WorkbenchRequestEditComponentdModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: 'c0418260459b-RequestorGridModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/RequestorGridModule.vendor.bundle.js',
                    baseUrl + '/RequestorGridModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: 'c0418260459b-VergeModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/VergeModule.vendor.bundle.js',
                    baseUrl + '/VergeModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: 'c0418260459b-VergeFeedbackModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/VergeFeedbackModule.vendor.bundle.js',
                    baseUrl + '/VergeFeedbackModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: 'c0418260459b-WorkbenchNotificationEmailModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/WorkbenchNotificationEmailModule.vendor.bundle.js',
                    baseUrl + '/WorkbenchNotificationEmailModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: 'c0418260459b-CSAModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/CSAModule.vendor.bundle.js',
                    baseUrl + '/CSAModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: 'c0418260459b-MainContainerModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/MainContainerModule.vendor.bundle.js',
                    baseUrl + '/MainContainerModule.bundle.js'
                ],
                sequentialLoading: true           
            },

    ]
}
}

PartnerAppRegistrationService.registerLazyLoadedApp(GrmWebApplication_routes, 'grmWebApplication');
